import { Client } from '@/types/schema';
import { QuickbooksPayment } from './qb-payments';
import { BillAddr, Line } from './quickbooks';

export interface QuickbooksInvoice {
	AllowIPNPayment: boolean,
	AllowOnlinePayment: boolean,
	AllowOnlineCreditCardPayment: boolean,
	AllowOnlineACHPayment: boolean,
	domain: Domain,
	sparse: boolean,
	Id: string,
	SyncToken: string,
	MetaData: MetaData,
	CustomField: CustomField[],
	DocNumber: string,
	TxnDate: Date,
	CurrencyRef: Ref,
	LinkedTxn: LinkedTxn[],
	Line: Line[],
	discounts?: Line[],
	TxnTaxDetail: TxnTaxDetail,
	Customer?: Client,
	CustomerRef: Ref,
	CustomerMemo?: CustomerMemo,
	BillAddr: BillAddr,
	ShipAddr?: BillAddr,
	SalesTermRef?: Ref,
	TotalAmt: number,
	ApplyTaxAfterDiscount: boolean,
	PrintStatus: PrintStatus,
	EmailStatus: EmailStatus,
	BillEmail?: BillEmail,
	Balance: number,
	PrivateNote?: string,
	DeliveryInfo?: DeliveryInfo,
	DueDate: string,
	IssueDate: string,
	CreatedAt?: Date,
	UpdatedAt?: Date
}

export type QbVendor = {
	PrimaryEmailAddr: {
		Address: string
	},
	BillAddr?: BillAddr,
	Balance: number,
	AcctNum: string,
	Vendor1099: boolean,
	CurrencyRef?: {
		value: string,
		name: string
	},
	domain: string,
	sparse: boolean,
	Id: string,
	SyncToken: string,
	MetaData: {
		CreateTime: string,
		LastUpdatedTime: string
	},
	GivenName: string,
	FamilyName: string,
	CompanyName: string,
	DisplayName: string,
	PrintOnCheckName: string,
	Active: boolean,
	V4IDPseudonym?: string,
	PrimaryPhone: {
		FreeFormNumber: string
	},
	WebAddr: {
		URI: string
	}
};

export interface BillEmail {
	Address: string
}

export interface Ref {
	value: string,
	name: string
}

export interface CustomField {
	DefinitionId: string,
	Name: Name,
	Type: Type,
	StringValue?: string
}

export enum Name {
	Crew = 'Crew #',
}

export enum Type {
	StringType = 'StringType',
}

export interface CustomerMemo {
	value: string
}

export interface DeliveryInfo {
	DeliveryType: string
}

export enum EmailStatus {
	NeedToSend = 'NeedToSend',
	NotSet = 'NotSet',
	EmailSent = 'EmailSent',
}

export enum LineDetailType {
	DiscountLineDetail = 'DiscountLineDetail',
	SalesItemLineDetail = 'SalesItemLineDetail',
	SubTotalLineDetail = 'SubTotalLineDetail',
}

export interface DiscountLineDetail {
	PercentBased: boolean,
	DiscountPercent: number,
	DiscountAccountRef: Ref
}

export interface LinkedTxn {
	TxnId: string,
	TxnType: TxnType,
	Payment: QuickbooksPayment
}

export enum TxnType {
	Estimate = 'Estimate',
	Payment = 'Payment',
	ReimburseCharge = 'ReimburseCharge',
	StatementCharge = 'StatementCharge',
	TimeActivity = 'TimeActivity',
}

export type Uom = {
	price: number,
	cost: number,
	selected: boolean
};

export interface SalesItemLineDetail {
	ItemRef: Ref,
	UnitPrice?: number,
	Qty?: number,
	TaxCodeRef?: CustomerMemo,
	ServiceDate?: Date,
	uoms?: Uom[]
}

export interface MetaData {
	CreateTime: Date,
	LastUpdatedTime: Date
}

export enum PrintStatus {
	NeedToPrint = 'NeedToPrint',
	NotSet = 'NotSet',
}

export interface TxnTaxDetail {
	TotalTax: number,
	TxnTaxCodeRef?: CustomerMemo,
	TaxLine?: TaxLine[]
}

export interface TaxLine {
	Amount: number,
	DetailType: TaxLineDetailType,
	TaxLineDetail: TaxLineDetail
}

export enum TaxLineDetailType {
	TaxLineDetail = 'TaxLineDetail',
}

export interface TaxLineDetail {
	TaxRateRef: CustomerMemo,
	PercentBased: boolean,
	TaxPercent: number,
	NetAmountTaxable: number
}

export enum Domain {
	Qbo = 'QBO',
}
